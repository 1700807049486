import React from 'react';
import ProjectCard from "../project/ProjectCard";


const ProfileProjects = ({projects}) => (
    <div className="row">
        {projects.map(project => {
            return <ProjectCard project={project} key={project.id}/>
        })}
    </div>
)

export default ProfileProjects;