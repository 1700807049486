import React from 'react';
import axios from "axios";

import { jsx, css } from '@emotion/core';

import Config from "../config/Config.js";

import {
    RESOLVE_LOCAL_AUTH_STATE,
    RESOLVE_LOCAL_USER_STATE
  } from "../components/auth/AuthActions";


import ProjectCard from "../components/project/ProjectCard";
import SweetLoadingSpinner from '../components/eyeCandy/SweetLoadingSpinner';

class SearchResultsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: RESOLVE_LOCAL_AUTH_STATE(),
            user: RESOLVE_LOCAL_USER_STATE(),
            searchQuery: this.props.location.search,
            searchResults: []
        };
    }
    componentDidMount() {
        return axios
            .get(
            `${Config().baseUrl}${this.state.searchQuery}`
            )
            .then(res => {
                console.log(res);
                this.setState({
                    loading: false,
                    searchResults: res.data
                });
            })
            .catch(err => {
            console.log(err);
            })
    }
    render() {
        const searchResultsFor = this.state.searchQuery.replace("?search=", "");
        return (            
            <div
                className="container"
                style={{
                    paddingTop: '50px',
                }}
            >
                <h6>Search results for "<strong>{searchResultsFor}</strong>"...</h6>
                <hr/>
                <SweetLoadingSpinner loadingState={this.state.loading} />
                <div className="row">
                {this.state.searchResults.map(project => {
                    return <ProjectCard project={project} key={project.id} />;
                })}
                </div>
            </div>
        )
    }
}

export default SearchResultsPage;