import React from 'react';
import { Link } from 'react-router-dom';

import ProjectContributorCard from './ProjectContributorCard';

const ProjectContributorList = ( { users = [] } ) => (
    <div className="row">
        {users.map(user_id => {
            return <ProjectContributorCard user_id={user_id} />
        })}
    </div>
)

export default ProjectContributorList;