import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import Config from "../config/Config.js";
import { RESOLVE_LOCAL_AUTH_STATE, RESOLVE_LOCAL_USER_STATE } from '../components/auth/AuthActions';
import OwnedProjects from '../components/user/OwnedProjects';

import UserCard from '../components/user/UserCard';

import SweetLoadingSpinner from "../components/eyeCandy/SweetLoadingSpinner.js";

class Home extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            auth: RESOLVE_LOCAL_AUTH_STATE(),
            user: RESOLVE_LOCAL_USER_STATE(),
            extended_user: {},
            loading: true
        }
    }
    componentDidMount() {
        if (this.state.auth.is_authenticated === "true") {
            console.log("You are authenticated.");
        } else if (this.state.auth.is_authenticated === "false") {
            console.log("You are not authenticated.");
        } else {
            console.log("There seems to be a problem with our authentication state.");
        }
        // hitting API: extended_user
        axios
            .get(`${Config().extendedUserUrl}${this.state.user.pk}/`)
            .then(res => {
                this.setState({
                    extended_user: res.data,
                    loading: false
                })
            });
    }
    render() {
        console.log(this.state.extended_user);
        return (
            <div
                className="container"
                style={{
                    paddingTop: '50px',
                }}
            >
                <div 
                    className="row"
                >
                    <div className="col-md-3 col-sm-3 col-xs-12">
                        <SweetLoadingSpinner loadingState={this.state.loading} />
                        <UserCard user={this.state.user} extended_user={this.state.extended_user} settings={true} back_home={false} view_profile={true} />
                    </div>
                    <div className="col-md-9 col-sm-9">
                        <h6><span style={{color: 'black', backgroundColor:'rgba(0,0,0,.05', padding: '5px'}}>Projects</span></h6>
                        <hr />
                        <a
                            href="/projects/new"
                            title="Create new project"
                            style={{
                                padding: '10px 10px',
                                backgroundColor:'black',
                                textDecoration: 'none',
                                borderRadius: '100px',
                                margin: '5px',
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} color='white' size='lg' />
                        </a>
                        <br/>
                        <br/>
                        <div className="container">
                            <SweetLoadingSpinner loadingState={this.state.loading} />
                            <OwnedProjects />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;