import React from 'react';
import SweetLoadingSpinner from '../eyeCandy/SweetLoadingSpinner'
import ProjectList from './ProjectList';


class TrendingProjects extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true
        }
    }
    componentDidMount() {
        this.setState({
            loading: false
        });
    }
    render() {
        return(
            <div
                className="container"
                style={{
                    paddingTop: '50px',
                }}
            >
                <h5>Recent Projects</h5>
                <p style={{fontStyle: 'italic', fontSize: '.85em'}}>By the inertia7 community:</p>
                <hr/>
                <SweetLoadingSpinner loadingState={this.state.loading} />
                <ProjectList publish={true} />
            </div>
        )
    }
}

export default TrendingProjects;