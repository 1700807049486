import React from "react";

import {
  RESOLVE_LOCAL_AUTH_STATE,
  RESOLVE_LOCAL_USER_STATE,
} from "../auth/AuthActions";

import axios from "axios";

import { Link } from "react-router-dom";

import Config from "../../config/Config.js";

import queryString from 'query-string';

import { jsx, css } from "@emotion/core";

// import { ClipLoader } from 'react-spinners';
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: rgb(0, 43, 54);
`;



class Activate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: RESOLVE_LOCAL_AUTH_STATE(),
      user: RESOLVE_LOCAL_USER_STATE(),
      activation_msg: '',
      redirect_status: false,
      loading: true

    };
  }

 
  componentDidMount(){
      const parsed = queryString.parse(this.props.location.search);
      const { api_activation_link } = parsed;
      const secure_api_activation_link = api_activation_link.replace("http:", "https:");
      console.log(secure_api_activation_link)

      axios
        .get(
            `${secure_api_activation_link}`
        )
        .then(res => {
            console.log(res);

            let redirect_status;
            if (res.status == 200) {
                redirect_status = true;
            }
            this.setState({
                activation_msg: res.data,
                redirect_status: redirect_status,
                loading: false,
            })
        });
  }

  render() {
    return (
            <div className="container signup-custom">
                <h1 className="">Activating account...</h1>
                <div className="sweet-loading">
                    <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={150}
                        color={"#123abc"}
                        loading={this.state.loading}
                    />
                </div>
                

                {
                    this.state.redirect_status 
                    &&
                    <div>
                        <p>{this.state.activation_msg}</p>
                        <Link to="/accounts/signin">Click here to continue.</Link>
                    </div>
                    
                }

            </div>
    );
  }
}

export default Activate;