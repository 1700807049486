import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'

const UserCard = ({ user, extended_user, settings=false, back_home=false, view_profile=false }) => (
    <div>
        
        <img src={extended_user.picture_link} width="100%" />
        <h3 style={{marginTop: '30px', fontWeight:'800'}}>{user.first_name} {user.last_name}</h3>

        <hr/>
        
        <p style={{fontSize: '12px'}}><FontAwesomeIcon icon={faUser} color="black"/> {extended_user.bio}</p>
        <p style={{fontSize: '12px'}}><FontAwesomeIcon icon={faEnvelope} color="black"/> {user.email}</p>
        <p style={{fontSize: '12px'}}><FontAwesomeIcon icon={faGlobeAmericas} color="black"/> {extended_user.website}</p>
        <hr />
        {settings == true && (
            <div style={{textAlign:'center', backgroundColor:'rgba(0,0,0,.05)', padding: '15px'}}>                    
                <Link to={`/settings`}>
                    <FontAwesomeIcon icon={faCog} color="black" size="2x" />
                </Link>
            </div>
        )}

        {back_home == true && (
            <div style={{textAlign:'center', fontSize: '10px'}}>
                <p
                    style={{
                        backgroundColor:'rgba(0,0,0,.9)',
                        padding: '15px',
                        color: 'white'
                    }}
                >
                    This is your public profile. 
                </p>
                <Link to={`/home`}>
                    <a>
                        Back to home
                    </a>
                </Link>
            </div>
        )}

        {view_profile == true && (
            <div style={{textAlign:'center', backgroundColor:'rgba(0,0,0,.00)', padding: '10px', fontSize: '10px'}}>
                <Link to={`/${user.username}`}>
                    <a>
                        View public profile
                    </a>
                </Link>
            </div>
        )}
    </div>
)

export default UserCard;