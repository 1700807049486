import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

import {
  RESOLVE_LOCAL_AUTH_STATE,
  RESOLVE_LOCAL_USER_STATE
} from "../auth/AuthActions";

import axios from "axios";

import Config from "../../config/Config.js";

import PersonalChange from './PersonalChange';
import ProfileChange from './ProfileChange';
import PwdChange from './PwdChange';

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: RESOLVE_LOCAL_AUTH_STATE(),
      user: RESOLVE_LOCAL_USER_STATE(),
      current_password: "",
      new_password1: "",
      new_password2: ""
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    axios
    .post(
      `${Config().pwdChangeUrl}`,     
      {
        new_password1: this.state.new_password1,
        new_password2: this.state.new_password2
      },
      {
        username: this.state.auth.username,
        auth: {
          username: this.state.auth.username,
          password: this.state.current_password
        }
      }
    )
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    });
  };

  render() {
    return (
      <div className="container signup-custom">
        <div className="row">
          <div className="col-sm-2 col-xs-2" >
                
                <h1 className="">Settings</h1>
                
                <hr />

                <Link onClick={this.forceUpdate} to={`/settings/profile-change`}>    
                <h5 style={{color: 'rgb(36, 81, 93)', fontWeight: '700'}}>Profile</h5>
                </Link>
                <p>Update your bio, CV, website, and picture.</p>
                
                <br />
                
                <Link onClick={this.forceUpdate} to={`/settings/personal-change`}>
                <h5 style={{color: 'rgb(36, 81, 93)', fontWeight: '700'}}>Personal Information</h5>
                </Link>
                <p>Update your first name, last name, and email address.</p>                

                <br />

                <Link onClick={this.forceUpdate} to={`/settings/password-change`}>    
                <h5 style={{color: 'rgb(36, 81, 93)', fontWeight: '700'}}>Password</h5>
                </Link>
                <p>Update your password.</p>
                </div>
          
          <div className="col-sm-9 col-xs-9 offset-md-1 offset-sx-1">
              <Router>
                  <Switch>
                    <Route exact={true} path="/settings/personal-change" component={PersonalChange} />
                    <Route exact={true} path="/settings/profile-change" component={ProfileChange} />
                    <Route exact={true} path="/settings/password-change" component={PwdChange} />
                  </Switch>
              </Router>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;