import React from 'react';
import axios from 'axios';

import ProjectCard from "../project/ProjectCard";
import Config from "../../config/Config.js";

import { RESOLVE_LOCAL_AUTH_STATE, RESOLVE_LOCAL_USER_STATE } from '../auth/AuthActions';

class OwnedProjects extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            auth: RESOLVE_LOCAL_AUTH_STATE(),
            user: RESOLVE_LOCAL_USER_STATE(),
            owned_projects: []
        }
    }

    componentDidMount() {
        axios
            .get(`${Config().baseUrl}?owner=${this.state.user.pk}`)
            .then(res => {
                this.setState({
                    owned_projects: res.data
                })
            });
    }

    render() {
        return(
            <div className="row">
                {this.state.owned_projects.map(project => {
                    return <ProjectCard project={project} key={project.id} />
                })}
            </div>
        )
    }
}

export default OwnedProjects;