import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Config from '../../config/Config.js'


class ProjectContributorCard extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: {},
            extended_user: {}
        }
    }

    componentDidMount() {
        console.log(this.props.user_id);
        axios
            .get(
                `${Config().userUrl}${this.props.user_id}`
            )
            .then(res1 => {
                console.log(res1);
                const returned_user = res1.data;
                axios
                    .get(
                        `${Config().extendedUserUrl}${this.props.user_id}`
                    )
                    .then(res2 => {
                        const returned_extended_user = res2.data;
                        this.setState({
                            user: returned_user,
                            extended_user: returned_extended_user
                        })
                    })
            })
    }
    render() {
        return(
            <div
                className="col-xl-1 col-lg-1 col-md-1 col-xs-3"
                style={{
                    textAlign: 'center',
                    marginTop: '20px',
                }}
            >
                <a
                    href={`/${this.state.user.username}`}
                >
                    <img
                        src={this.state.extended_user.picture_link}
                        style={{
                            borderRadius: '50%',
                            height: '50px',
                            width: '50px',
                            // marginTop: '14px',
                            // marginBottom: '10px',
                        }}
                        alt=""
                    />
                    <p
                        style={{
                            fontSize: '.7em',
                            // fontWeight: '600',
                            color: 'black'
                        }}
                    >
                        {this.state.user.first_name} {this.state.user.last_name}
                    </p>
                </a>
            </div>
        )
    }

}



export default ProjectContributorCard;





