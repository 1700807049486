import React from "react";

import {
  RESOLVE_LOCAL_AUTH_STATE,
  RESOLVE_LOCAL_USER_STATE,
} from "../auth/AuthActions";

import axios from "axios";

import Config from "../../config/Config.js";

class PersonalChange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: RESOLVE_LOCAL_AUTH_STATE(),
      user: RESOLVE_LOCAL_USER_STATE(),
      new_first_name: RESOLVE_LOCAL_USER_STATE().first_name,
      new_last_name: RESOLVE_LOCAL_USER_STATE().last_name,
      new_email: RESOLVE_LOCAL_USER_STATE().email
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    axios
    .put(
      `${Config().userUrl}${this.state.user.pk}/`,     
      {
        username: this.state.user.username,
        first_name: this.state.new_first_name,
        last_name: this.state.new_last_name,
        email: this.state.new_email
      },
      {
        'headers': {
          "Authorization": `Token ${this.state.auth.token}`
        }
      }
    )
    .then(res => {
      console.log(res);
      localStorage.setItem("USER_EMAIL", res.data.email);
      localStorage.setItem("USER_FIRST_NAME", res.data.first_name);
      localStorage.setItem("USER_LAST_NAME", res.data.last_name);
      window.location = "/settings";

    })
    .catch(err => {
      console.log(err);
    });
  };

  componentDidMount(){
      console.log(this.state.user)
  }

  render() {
    return (
            <form onSubmit={this.handleSubmit}>
              <h1 className="">Personal information</h1>
            <hr />
              
              <div className="input-field">
                <label htmlFor="new_first_name">First name</label>
                <input
                  className="form-control auth-form"
                  type="text"
                  id="new_first_name"
                  value={this.state.new_first_name}
                  onChange={this.handleChange}
                />
              </div>

              <br />

              <div className="input-field">
                <label htmlFor="new_last_name">Last name</label>
                <input
                  className="form-control auth-form"
                  type="text"
                  id="new_last_name"
                  value={this.state.new_last_name}
                  onChange={this.handleChange}
                />
              </div>

              <br />

              <div className="input-field">
                <label htmlFor="new_email">Email</label>
                <input
                  className="form-control auth-form"
                  type="text"
                  id="new_email"
                  value={this.state.new_email}
                  onChange={this.handleChange}
                />
              </div>

              <br />

              <div className="input-field">
                <button className="">Save changes</button>
              </div>
            </form>

    );
  }
}

export default PersonalChange;