import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

import Favicon from 'react-favicon';

import RootPage from './pages/RootPage';
import HomePage from './pages/HomePage';
import SearchResultsPage from './pages/SearchResultsPage';

import ProfilePage from './pages/ProfilePage';

import NavigationBar from './components/layout/NavigationBar';
import Footer from './components/layout/Footer';

import './style/bootstrap.css'
import './style/custom.css'

import SignUp from './components/auth/SignUp';
import SignIn from './components/auth/SignIn';
import Activate from './components/auth/Activate';
import PostSignUp from './components/auth/PostSignUp';
import ResetPassword from './components/auth/ResetPassword';
import ResetPasswordConfirm from './components/auth/ResetPasswordConfirm';

import Settings from './components/settings/Settings';
import ProjectDetail from './components/project/ProjectDetail';
import ProjectCreate from './components/project/ProjectCreate';
import ProjectUpdate from './components/project/ProjectUpdate';

class App extends Component {

  render() {
    document.title = "Inertia7";
    return (
      <div>
        <Favicon url="https://i.imgur.com/QABosqY.png" />
        <NavigationBar />
        <Router>
          <Switch>
            <Route exact path="/accounts/register" component={SignUp} />
            <Route exact path="/accounts/signin" component={SignIn} />
            <Route exact path="/accounts/activate" component={Activate} />
            <Route exact path="/accounts/confirm" component={PostSignUp} />
            <Route exact path="/accounts/password-reset" component={ResetPassword} />
            <Route exact path="/accounts/password-reset/confirm" component={ResetPasswordConfirm} />

            <Route path="/settings" component={Settings} />
            <Route exact path="/search" component={SearchResultsPage} />
            <Route exact path="/home" component={HomePage} />
            <Route exact path="/projects/:id(\d+)/edit" component={ProjectUpdate} />
            <Route exact path="/projects/:id(\d+)" component={ProjectDetail} />
            <Route exact path="/projects/new" component={ProjectCreate} />

            <Route exact path="/:username(\w+)" component={ProfilePage} />
            <Route exact path="/" component={RootPage} />
          </Switch>
        </Router>
        <Footer />
      </div>
    );
  }
}

export default App;
