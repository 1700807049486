import React from 'react';

// loading spinner
import { jsx, css } from '@emotion/core'
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: rgb(0, 43, 54);
`;

class Welcome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        });
    }

    render() {
        return(
            <div className="container">
                <div className="row">                                            
                    <div
                        className="header-custom"
                        style={{
                            paddingTop: "150px"
                        }}
                    >
                        <h4 style={{color: 'gray'}}>Welcome to Inertia7</h4>
                        <br/>
                        <span className="inertia7-top-color"><h1><span style={{fontWeight:'800'}}>Create</span> and <span style={{fontWeight:'800'}}>share</span> <span style={{backgroundColor:'#632266', borderRadius: '3px', color: 'white', padding: '4px'}}>software</span> &amp; <span style={{backgroundColor:'#24515d', borderRadius: '3px', color: 'white', padding: '4px'}}>data science</span> <span style={{fontWeight:'800'}}>projects</span>!</h1></span>
                        <br />
                        <div style={{textAlign: 'center'}}>
                            <a href="/accounts/signin/"><button style={{backgroundColor:'black'}}><strong>Sign In</strong></button></a>
                            <span style={{padding: '10px'}}>or</span>
                            <a href="/accounts/register/"><button style={{backgroundColor:'black', color:'white'}}><strong>Join, it's free!</strong></button></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Welcome;