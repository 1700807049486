import React from 'react';
import axios from 'axios';

import ProjectCard from "../project/ProjectCard";
import Config from "../../config/Config.js";
import OwnedProjects from "./OwnedProjects";

import { RESOLVE_LOCAL_AUTH_STATE, RESOLVE_LOCAL_USER_STATE } from '../auth/AuthActions';

class GreetUser extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            auth: RESOLVE_LOCAL_AUTH_STATE(),
            user: RESOLVE_LOCAL_USER_STATE(),
            owned_projects: []
        }
    }

    componentDidMount() {
        const x = `${Config().baseUrl}?owner=${this.state.user.pk}&limit=6`;
        console.log(x);
        axios
            .get(x)
            .then(res => {
                this.setState({
                    owned_projects: res.data.results
                })
            });
    }

    render() {
        return(
            <div 
                className='container'
                style={{
                    paddingTop: '50px',
                }}
            >
                <h5>
                    Welcome <a href='/home' style={{color:'black', fontWeight:'bold'}}>{this.state.user.first_name}</a>!
                </h5>
                <hr/>
                <p style={{fontStyle: 'italic', fontSize: '.85em'}}>Here are your most recent projects:</p>
                <div 
                    className="row"
                    style={{
                        // backgroundColor: 'black',
                        // padding: '10px 0px',
                        // borderStyle: 'solid',
                        // borderColor: 'rgba(89, 198, 193, .2)',
                    }}
                >
                    {this.state.owned_projects.map(project => {
                        return <ProjectCard project={project} key={project.id} />
                    })}
                </div>
            </div>
        )
    }
}

export default GreetUser;