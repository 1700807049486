/* eslint-disable default-case */

// -----------------------------------
// IMPORTS
// -----------------------------------
import React from "react";
import axios from "axios";


import { jsx, css } from '@emotion/core'


// import { ClipLoader } from 'react-spinners';
import ClipLoader from 'react-spinners/ClipLoader';
import { Checkbox } from 'semantic-ui-react';
import Toggle from 'react-toggle'

// custom imports
import Config from "../../config/Config.js";
import {
  RESOLVE_LOCAL_AUTH_STATE,
  RESOLVE_LOCAL_USER_STATE
} from "../auth/AuthActions";
import { faThList } from "@fortawesome/free-solid-svg-icons";
import Redirect from "react-router-dom/Redirect";
// third-party imports
const ReactMarkdown = require("react-markdown");



const override = css`
    display: block;
    margin: 0 auto;
    border-color: rgb(0, 43, 54);
`;

// -----------------------------------
// COMPONENT: ProjectUpdate
// -----------------------------------
class ProjectUpdate extends React.Component {
  // constructor & state
  constructor(props) {
    super(props);
    this.state = {
      // user & auth data
      auth: RESOLVE_LOCAL_AUTH_STATE(),
      user: RESOLVE_LOCAL_USER_STATE(),
      // project data
      project_id: props.match.params.id,
      title: "",
      subtitle: "",
      body: "",
      technology: "",
      skill: "",
      picture_link: "",
      publish: "",
      loading: true
    };
  }

  componentDidMount() {
    console.log("ProjectUpdate");
    axios
      .get(`${Config().baseUrl}${this.state.project_id}`)
      .then(res => {
        this.setState({
          title: res.data.title,
          subtitle: res.data.subtitle,
          body: res.data.body,
          technology: res.data.technology,
          skill: res.data.skill,
          picture_link: res.data.picture_link,
          publish: res.data.publish,
          loading: false
        });
      })
      .catch(error => console.log(error));
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = (e, requestType = "put") => {
    e.preventDefault();
    switch (requestType) {
      case "put":
        return axios
          .put(
            `${Config().baseUrl}${this.state.project_id}/`,
            {
              'owner' : [ this.state.user.pk ],
              'title' : this.state.title,
              'subtitle': this.state.subtitle,
              'body' : this.state.body,
              'technology': this.state.technology,
              'skill': this.state.skill,
              'publish': this.state.publish,
              'picture_link': this.state.picture_link
            },
            {
              headers: {
                'Authorization': `Token ${this.state.auth.token}`
              }
            }
          )
          .then(res => {
            console.log(res);
            window.location = `/projects/${res.data.id}`;
          })
          .catch(error => console.log(error));
    }
  };

  cancelChanges = () => {
    window.location = "/";
  };

  handleDelete = (e, requestType = "delete") => {
    console.log('Attempting to delete project.');
    e.preventDefault();
    switch(requestType) {
      case "delete":
        return axios
          .delete(
            `${Config().baseUrl}${this.state.project_id}`,
            {
              'headers': {
                'Authorization': `Token ${this.state.auth.token}`
              }
            }
          )
          .then(() => {
            this.props.history.push("/");
            console.log('Project deleted successfully.');
          })
          .catch(err => {
            console.log(err);
          });
    }
  }

  toggleCheckBox = () => {
    this.setState({publish:!this.state.publish});
  }

  render() {
    return (
      <div className="project-create-edit-custom-container">
        <div className="row">
          
          
          <div className="col-sm-6 col-xs-11 col-xs-offset-1 custom-editor-input">
            
            <div className="">
              <h5 className="createEdit-project-preview-render">Editor</h5>
            </div>

            <div
              style={{
                fontSize:".85em",
                textAlign:"right",
                marginTop:"15px",
                fontWeight: '600',
              }}
            >
              <a 
                href="https://www.inertia7.com/projects/133"
                target="_blank"
                style={{
                  color: 'rgb(89, 198, 193)'
                }}
              >
                Tutorial: Creating a Project on Inertia7
              </a>
            </div>
            
            <div className='sweet-loading'>
              <ClipLoader
                  css={override}
                  sizeUnit={"px"}
                  size={150}
                  color={'#123abc'}
                  loading={this.state.loading}
              />
            </div> 
            
            <form onSubmit={this.handleSubmit} className="">

              <div className="input-field project-create-form-title">
                <label htmlFor="title">Title</label>
                <input
                  className="form-control"
                  placeholder="New project title..."
                  type="title"
                  id="title"
                  name="title"
                  value={this.state.title}
                  onChange={this.handleChange}
                />
              </div>

              <div className="input-field project-create-form-title">
                <label htmlFor="subtitle">Subtitle</label>
                <input
                  className="form-control"
                  placeholder="New project subtitle..."
                  type="subtitle"
                  id="subtitle"
                  name="subtitle"
                  value={this.state.subtitle}
                  onChange={this.handleChange}
                />
              </div>

              <div className="input-field project-create-form-title">
                <label htmlFor="technology">Technology</label>
                <input
                  className="form-control"
                  placeholder="Technology (e.g. Python, R, JavaScript)..."
                  type="technology"
                  id="technology"
                  name="technology"
                  value={this.state.technology}
                  onChange={this.handleChange}
                />
              </div>

              <div className="input-field project-create-form-title">
                <label htmlFor="skill">Skill</label>
                <input
                  className="form-control"
                  placeholder="Technology (e.g. programming, data-modeling, etc)..."
                  type="skill"
                  id="skill"
                  name="skill"
                  value={this.state.skill}
                  onChange={this.handleChange}
                />
              </div>

              <div className="textarea-field custom-hacky-form project-create-form-title">
              
                <label htmlFor="body">Body (Markdown, HTML)</label>
                <textarea
                  className="form-control"
                  type="textarea"
                  id="body"
                  name="body"
                  value={this.state.body}
                  onChange={this.handleChange}
                />
              </div>

              <div
                className="input-field"
                style={{
                  // margin: '10px',
                }}
              >
                <label>
                  <span
                    style={{
                      fontSize: '35px',
                      marginRight: '10px'
                    }}
                  >
                    Publish
                  </span>
                  <Toggle
                    checked={this.state.publish} 
                    onChange={this.toggleCheckBox}
                  />
                </label>
              </div>

              <div>
                <button className="btn btn-dark btn-lg custom-btn-save-cancel">
                  Save
                </button>
                <button
                  className="btn btn-outline-secondary btn-lg custom-btn-save-cancel"
                  type="reset"
                  value="Reset"
                  onClick={this.cancelChanges}
                >
                  Cancel
                </button>
              </div>
            </form>

          </div>

          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-11 custom-editor-render">
            <div className="">
              <h5 className="createEdit-project-preview-render">Preview</h5>
            </div>
            <br />
            <div>
              <div className='sweet-loading'>
                <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={150}
                    color={'#123abc'}
                    loading={this.state.loading}
                />
              </div> 
              <h1 className="createEdit-project-title-render">
                {this.state.title}
              </h1>
              <h4>
                {this.state.subtitle}
              </h4>
              <hr />
              <p>
                Technology: <strong>{this.state.technology}</strong>
              </p>
              <p>
                Skill: <strong>{this.state.skill}</strong>
              </p>
            </div>
            <hr />
            <div className='sweet-loading'>
              <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={150}
                color={'#123abc'}
                loading={this.state.loading}
              />
            </div> 
            <div className="createEdit-project-body-render">
              <ReactMarkdown
                source={this.state.body}
                escapeHtml={false}
                skipHtml={false}
              />
            </div>

            
          </div>          
        </div>

        <div
              style={{
                textAlign: "center",
                margin: "30px 10px",
                padding: "20px",
                border: "5px solid rgba(0,0,0,.1)",

              }}
            >
              <form onSubmit={this.handleDelete}>
                <label htmlFor="delete">Danger area</label>
                <p
                  style={{
                    fontSize: ".7em",
                  }}
                >
                  If you click the button below, you will permanently delete this project. There is no turning back.
                </p>
                <br />
                <button
                  className="btn btn-lg btn-danger"
                  type="delete"
                  value="delete"
                >
                  Delete Project
                </button>
              </form>
            </div>

      </div>
    );
  }
}

export default ProjectUpdate;