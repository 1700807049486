import axios from 'axios';
import Config from '../../config/Config.js'

export const FETCH_USER = (username) => {
    return axios
        .get(
            `${Config().userUrl}?username=${username}`
        )
        .then(res => {
            return res.data[0]
        })
};

export const FETCH_EXTENDED_USER = (id) => {
    return axios
        .get(
            `${Config().extendedUserUrl}${id}`
        )
        .then(res => {
            return res.data
        });
}

export const FETCH_PROJECTS = (id, publish) => {
    return axios
        .get(`${Config().baseUrl}?owner=${id}&publish=${publish}`)
        .then(res => {
            return res.data
        });
}