import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

import logo from "../../images/logo.png";

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // state
        }
    }

    render() {
        return (
            <div className="footer-custom">
                <img src={logo} width="60px" />
                <h5>Inertia7</h5>
                <p>A platform for creating and sharing software &amp; data science projects.</p>
                <p style={{fontSize:'.7em'}}>Built with <FontAwesomeIcon icon={faHeart} /> in New York City.</p>
            </div>
        );
    }
}

export default Footer;