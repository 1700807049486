import React from 'react';

// loading spinner
import { jsx, css } from '@emotion/core'
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: rgb(0, 43, 54);
`;

const SweetLoadingSpinner = ({ loadingState }) => (
    <div className='sweet-loading'>
        <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={'#123abc'}
            loading={loadingState}
        />
    </div>
)



export default SweetLoadingSpinner;