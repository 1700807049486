// react imports
import React from "react";
import axios from "axios";

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faFire } from '@fortawesome/free-solid-svg-icons';

// Material-UI
import EditIcon from '@material-ui/icons/Edit';

import ProjectContributorsList from './ProjectContributorsList';
import CodeBlock from "./CodeBlock.js";
import Config from "../../config/Config.js";
import ReactMarkdown from 'react-markdown';
import SweetLoadingSpinner from "../eyeCandy/SweetLoadingSpinner.js";


// ProjectDetail component
class ProjectDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      project_id: props.match.params.id,
      project: {},
      canEdit: false,
      loading: true
    };
  }

  componentDidMount() {
    console.log("Component mounting.");
    console.log(Config().baseUrl);
    axios.get(`${Config().baseUrl}${this.state.project_id}`).then(res => {
      this.setState({
        project: res.data,
        canEdit: res.data.owner.includes(
          parseInt(localStorage.getItem("USER_PK"))
        ),
        loading: false
      });
      console.log(res);
    });
    window.scrollTo(0, 0);
  }

  render() {
    var editButton = null;
    if (this.state.canEdit == true) {
      // editButton = <button style={{backgroundColor: 'black'}}><FontAwesomeIcon icon={faEdit} color='white' size='lg' /></button>;
      editButton = <EditIcon fontSize='large' style={{color: 'rgb(36, 81, 93)'}} className='pretty-button' color="action" />;
    }

    console.log(this.state.project.owner);
    
    return (
      <div className="container project-detail-custom">
        <div
          style={{
            // backgroundColor: 'rgba(0,0,0,.85)',
            backgroundSize: '100% 100%',
            // backgroundImage: `url(${this.state.project.picture_link})`,
            marginBottom: '10px',
          }}
        >
          <div
            width='100%'
            style={{
              // backgroundColor: 'rgba(0,0,0,.7)',
              // color: 'white',
              padding: '2% 0',
              // paddingBottom: '2%',
            }}
          >
            <SweetLoadingSpinner loadingState={this.state.loading} />
            <h1 style={{fontWeight:'800'}}>{this.state.project.title}</h1>
            <h3>{this.state.project.subtitle}</h3>
            <p style={{fontFamily: 'Courier', color: '#59c6c1'}}>
            <strong>{this.state.project.technology} <span style={{color: 'lightgray'}}>|</span> {this.state.project.skill}</strong>
            <ProjectContributorsList users={this.state.project.owner} />
          </p>
          </div>
        </div>

        <div className="project-detail-body-custom">
          <div style={{textAlign:'right'}}>
            <a href={`/projects/${this.state.project_id}/edit`}>{editButton}</a>
          </div>
          {/* <hr /> */}
          <hr />
          <br />
          <ReactMarkdown
            className="custom-body-markdown"
            source={this.state.project.body}
            // renderers={{
            //   code: CodeBlock
            // }}
            escapeHtml={false}
            skipHtml={false}
          />
        </div>
        <SweetLoadingSpinner loadingState={this.state.loading} />
      </div>
    );
  }
}

export default ProjectDetail;
