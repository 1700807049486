import React from "react";

import {
  RESOLVE_LOCAL_AUTH_STATE,
  RESOLVE_LOCAL_USER_STATE,
} from "../auth/AuthActions";

import axios from "axios";
import { Link } from "react-router-dom";
import Config from "../../config/Config.js";
import { jsx, css } from "@emotion/core";

class PostSignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: RESOLVE_LOCAL_AUTH_STATE(),
      user: RESOLVE_LOCAL_USER_STATE()
    };
  }

 
  componentDidMount(){
  }

  render() {
    return (
            <div className="container signup-custom">

                <br /><br />
                <h1 className="">Activate your account!</h1>
                <hr />
                <br /><br />
                <p>A confirmation email has been sent to <strong>{this.state.user.email}</strong>.</p>
                <p>Please check your email and activate your account!</p>

            </div>
    );
  }
}

export default PostSignUp;