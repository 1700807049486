import React from 'react';
import { jsx, css } from '@emotion/core';

import Welcome from '../components/marketing/Welcome';
import TrendingProjects from '../components/project/TrendingProjects';
import ProjectsBy from '../components/project/ProjectsBy';
import DiscoverBySkillTech from '../components/marketing/DiscoverBySkillTech';


import GreetUser from '../components/user/GreetUser';

import {
    RESOLVE_LOCAL_AUTH_STATE,
    RESOLVE_LOCAL_USER_STATE
  } from "../components/auth/AuthActions";

class RootPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: RESOLVE_LOCAL_AUTH_STATE(),
            user: RESOLVE_LOCAL_USER_STATE()
        };
    }
    componentDidMount() {
        this.setState({
            loading: false
        });

        console.log(this.state.auth);
        console.log(this.state.user);
    }
    render() {
        const is_authenticated = this.state.auth.is_authenticated === "true" ? true : false;
        return (            
            <div>
                {!is_authenticated && (
                    <Welcome />
                )}
                {is_authenticated && (
                    <GreetUser />
                )}
                <TrendingProjects publish={true} />
                <ProjectsBy question={'Python'} publish={true} />
                <ProjectsBy question={'R'} publish={true} />
            </div>
        )
    }
}

export default RootPage;