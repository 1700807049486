/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React from "react";
import axios from "axios";

import ReactGA from 'react-ga';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import Config from "../../config/Config.js";
        
import logo from "../../images/logo.png";

import {
  SIGN_OUT,
  RESOLVE_LOCAL_AUTH_STATE,
  RESOLVE_LOCAL_USER_STATE
} from "../auth/AuthActions";
import { withState } from "recompose";
import SimpleMenu from "../utils/SimpleMenu.js";


class NavigationBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: RESOLVE_LOCAL_AUTH_STATE(),
      user: RESOLVE_LOCAL_USER_STATE(),
      globalSearch: ''
    };
  }

  componentDidMount() {
    ReactGA.initialize("UA-54935029-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(this.state.auth);
  }

  handleGlobalSearchChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    })
    console.log(this.state.globalSearch);
  }

  handleGlobalSearchSubmit = e => {
    e.preventDefault();
    window.location = `/search/?search=${this.state.globalSearch}`;
  }

  handleSignOut = () => {
    const token = this.state.auth.token;
    SIGN_OUT(token);
  };

  render() {
    const is_authenticated = this.state.auth.is_authenticated === "true" ? true : false;

    return (

      
      // <nav className="navbar navbar-light bg-light">
      //   <div className="container-fluid">
      //     <a className="navbar-brand" href="#">
      //       <img src="/docs/5.0/assets/brand/bootstrap-logo.svg" alt="" width="30" height="24" className="d-inline-block align-top" />
      //       Bootstrap
      //     </a>
      //   </div>
      // </nav>

      // <nav class="navbar navbar-light bg-light">
      //   <div class="container-fluid">
      //     <a class="navbar-brand">Navbar</a>
      //     <form class="d-flex">
      //       <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
      //       <button class="btn btn-outline-success" type="submit">Search</button>
      //     </form>
      //   </div>
      // </nav>


// --------------------------


      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid container">
          <a className="navbar-brand pretty-button" href="/">
            <img
              src={logo} alt="" width="40" height="34" className="d-inline-block align-top"
              style={{
                // borderRadius: '3px',
                // borderColor: 'rgba(0,0,0,.01)',
                // borderStyle: 'solid',
                // backgroundColor: 'rgba(0,0,0,.01)',
                // boxShadow: '2px 2px 5px rgba(0,0,0,.2)',
              }}
            />
            {/* Inertia7 */}
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <form
              onSubmit={this.handleGlobalSearchSubmit}
              className="d-flex"
            >
              <input
                id="globalSearch"
                name="globalSearch"
                value={this.state.globalSearch}
                onChange={this.handleGlobalSearchChange}
                className="form-control me-2" type="search" placeholder="Search" aria-label="Search"
              />
              <button className="btn btn-dark" type="submit">Search</button>
            </form>
            <ul className="navbar-nav ml-auto mb-2 mb-lg-0">

              {/* {is_authenticated && (                
                <SimpleMenu
                  auth={
                    this.state.auth
                  }
                  user={
                    this.state.user
                  }
                  sign_out = {
                    this.handleSignOut
                  }
                />
              )} */}

              {is_authenticated && (
            <li className="nav-item">
              <a
                className="nav-link active pretty-button"
                aria-current="page"
                href="/home"
                >
                Home
              </a>
            </li>
          )}

          {is_authenticated && (
            <li className="nav-item">
              <a
                className="nav-link active pretty-button"
                aria-current="page"
                href="/projects/new"
                style={{
                  // backgroundColor: 'black',
                  color: '#24515d',
                  fontWeight: '700',
                }}
              >
                + New Project
              </a>
            </li>
          )}

          {is_authenticated && (
            <li className="nav-item">
              <a
                className="nav-link active pretty-button"
                aria-current="page"
                href="/settings"
              >
                Settings
              </a>
            </li>
          )}

          {is_authenticated && (
            <li className="nav-item">
              <a
                className="nav-link active pretty-button"
                aria-current="page"
                href="/"
                onClick={this.handleSignOut}
                // style={{
                //   backgroundColor: 'black',
                //   color: 'white',
                // }}
              >
                Sign Out
              </a>
            </li>
          )}
          {!is_authenticated && (
            <li className="nav-item">
              <a
                className="nav-link active pretty-button"
                aria-current="page"
                href="/accounts/signin"
                // style={{
                //   backgroundColor: 'black',
                //   color: 'white',
                // }}
              >
                Sign In
              </a>
            </li>
          )}
          {!is_authenticated && (
            <li className="nav-item">
              <a
                className="nav-link active pretty-button"
                aria-current="page"
                href="/accounts/register"
                // style={{
                //   backgroundColor: 'black',
                //   color: 'white',
                // }}
              >
                Join! It's free!
              </a>
            </li>
          )}

            </ul>
          </div>
        </div>
      </nav>


// -------------------------------------

    );
  }
}

export default NavigationBar;
