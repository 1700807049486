import React from 'react';
import SweetLoadingSpinner from '../eyeCandy/SweetLoadingSpinner'

import ProjectCard from "./ProjectCard";
import axios from "axios";
import { jsx, css } from '@emotion/core'

import Config from "../../config/Config.js";

class ProjectsBy extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            project_list: []
        }
    }
    componentDidMount() {
        console.log(`${Config().projectByTechnologyUrl}${this.props.question}&publish=${this.props.publish}`);
        axios.get(
            `${Config().projectByTechnologyUrl}${this.props.question}&publish=${this.props.publish}`
        ).then(res => {
            this.setState({
              project_list: res.data,
              loading: false
            });
          });
    }
    render() {
        return(
            <div
                className="container"
                style={{
                    marginTop: '50px',
                }}
            >
                <h6>{this.props.question} Projects</h6>
                <hr/>
                <SweetLoadingSpinner loadingState={this.state.loading} />
                <div>
                    <div className="row">
                    {this.state.project_list.map(project => {
                        return <ProjectCard project={project} key={project.id} />;
                    })}
                    </div>
                </div>
            </div>
        )
    }
}

export default ProjectsBy;