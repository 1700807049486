import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import ProfileProjects from '../components/profile/ProfileProjects'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import Config from "../config/Config.js";
import { RESOLVE_LOCAL_AUTH_STATE, RESOLVE_LOCAL_USER_STATE } from '../components/auth/AuthActions';
import OwnedProjects from '../components/user/OwnedProjects';

import UserCard from '../components/user/UserCard';

import SweetLoadingSpinner from "../components/eyeCandy/SweetLoadingSpinner.js";
import { FETCH_USER, FETCH_EXTENDED_USER, FETCH_PROJECTS } from '../components/profile/ProfileActions.js';

class ProfilePage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            auth_user: RESOLVE_LOCAL_USER_STATE(),
            profile_username: this.props.match.params.username,
            profile_user: '',
            profile_extended_user: '',
            profile_projects: [],
            loading: true
        }
    }
    componentDidMount() {
        FETCH_USER(this.state.profile_username).then(res => {
            const returned_user = res;
            FETCH_EXTENDED_USER(returned_user.id).then(res2 => {
                const returned_extended_user = res2;
                // fetching published projects by user id
                FETCH_PROJECTS(returned_user.id, true).then(res3 => {
                    const returned_projects = res3;
                    
                    // now resetting state after all that cake and watermelon
                    this.setState({
                        profile_user: returned_user,
                        profile_extended_user: returned_extended_user,
                        profile_projects: returned_projects,
                        loading: false
                    })
                })
            })
        })
    }
    render() {
        const auth_user_pk = this.state.auth_user.pk;
        const profile_user_pk = this.state.profile_user.pk;
        const auth_profile_user = (auth_user_pk == profile_user_pk);
        return (
            <div
                className="container"
                style={{
                    paddingTop: '50px',
                }}
            >
                <div className="row">
                    <div className="col-md-3 col-sm-3 col-xs-12">
                        <SweetLoadingSpinner loadingState={this.state.loading} />
                        <UserCard user={this.state.profile_user} extended_user={this.state.profile_extended_user} back_home={auth_profile_user} />
                    </div>
                    <div className="col-md-9 col-sm-9">
                        <h6><span style={{color: 'black', backgroundColor:'rgba(0,0,0,.05', padding: '5px'}}>Projects</span></h6>
                        <hr />
                        <div className="container">
                            <SweetLoadingSpinner loadingState={this.state.loading} />
                            <ProfileProjects projects={this.state.profile_projects} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfilePage;