import React from "react";
import { PASSWORD_RESET_2 } from "./AuthActions";

import queryString from 'query-string';

class ResetPasswordConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      new_password1: '',
      new_password2: '',
      uid: '',
      token: ''
    };
  }

  componentDidMount() {
    // hacky way of parsing api pwd reset credentials to get uid and token
    const parsed = queryString.parse(this.props.location.search);
    const stripped_parsed = parsed["api_email_reset_confirm_link"].replace("/api/v1/auth/password-reset/confirm/", "");
    const split_stripped_parsed = stripped_parsed.split("/");

    this.setState({
        uid: split_stripped_parsed[0],
        token: split_stripped_parsed[1]
    })
    
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { new_password1, new_password2, uid, token } = this.state;
    PASSWORD_RESET_2(new_password1, new_password2, uid, token);
  };

  render() {
    return (
      <div className="container signup-custom">
        <div className="row">
          <div className="col-sm-8 col-xs-12 col-sm-offset-1">
            <form onSubmit={this.handleSubmit}>
              <h1 className="">Choose a new password...</h1>

              <br />

              <div className="input-field">
                <label htmlFor="new_password1">Password</label>
                <input
                  className="form-control auth-form"
                  type="password"
                  id="new_password1"
                  onChange={this.handleChange}
                />
              </div>

              <br />

              <div className="input-field">
                <label htmlFor="new_password2">Confirm password</label>
                <input
                  className="form-control auth-form"
                  type="password"
                  id="new_password2"
                  onChange={this.handleChange}
                />
              </div>

              <br />

              <div className="input-field">
                <button className="">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPasswordConfirm;