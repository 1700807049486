/* eslint-disable default-case */

// -----------------------------------
// IMPORTS
// -----------------------------------
// react imports
import React from "react";
import axios from "axios";

import { jsx, css } from '@emotion/core'

// import { ClipLoader } from 'react-spinners';
import ClipLoader from 'react-spinners/ClipLoader';
import { Checkbox } from 'semantic-ui-react';



import Toggle from 'react-toggle'



// custom imports
import Config from "../../config/Config.js";
import {
  RESOLVE_LOCAL_AUTH_STATE,
  RESOLVE_LOCAL_USER_STATE
} from "../auth/AuthActions";
// third-party imports
const ReactMarkdown = require("react-markdown");

const override = css`
    display: block;
    margin: 0 auto;
    border-color: rgb(0, 43, 54);
`;

// -----------------------------------
// COMPONENT: ProjectCreate
// -----------------------------------
class ProjectCreate extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      // user & auth data
      auth: RESOLVE_LOCAL_AUTH_STATE(),
      user: RESOLVE_LOCAL_USER_STATE(),
      // project data
      title: "",
      subtitle: "",
      body: "",
      technology: "",
      skill: "",
      picture_link: "",
      publish: false,
      loading: true,
    };
  }

  componentDidMount() {
    console.log("Component mounting.");
        this.setState({
        loading: false
        });
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = (e, requestType = "post") => {
    e.preventDefault();
    switch (requestType) {
      case "post":
        return axios
          .post(
            `${Config().baseUrl}`,
            {
              'owner' : [ this.state.user.pk ],
              'title' : this.state.title,
              'subtitle': this.state.subtitle,
              'body' : this.state.body,
              'technology': this.state.technology,
              'skill': this.state.skill,
              'publish': this.state.publish,
              'picture_link': this.state.picture_link
            },
            {
              'headers': {
                "Authorization": `Token ${this.state.auth.token}`
              }
            }
          )
          .then(res => {
            console.log(res);
            window.location = `/projects/${res.data.id}`;
          })
          .catch(err => {
            console.log(err)
            const errMsgs = err.response.data;
            const keys = Object.keys(errMsgs);
        
            for (const key of keys) {
              const msg = key.concat(err.response.data[key]);
              alert(msg);
            }
          });
    }
  };

  cancelChanges = () => {
    window.location = "/";
  };

  toggleCheckBox = () => {
    this.setState({publish:!this.state.publish});
  }



  render() {
    return (
      <div className="project-create-edit-custom-container">
        <div className="row">
          <div className="col-sm-6 col-xs-11 col-xs-offset-1">
            
            <div className="">
              <h5 className="createEdit-project-preview-render">Editor</h5>
            </div>

            <div
              style={{
                fontSize:".85em",
                textAlign:"right",
                marginTop:"15px",
                fontWeight: '600',
              }}
            >
              <a 
                href="https://www.inertia7.com/projects/133"
                target="_blank"
                style={{
                  color: 'rgb(89, 198, 193)'
                }}
              >
                Tutorial: Creating a Project on Inertia7
              </a>
            </div>

            <form onSubmit={this.handleSubmit} className="">
              <div className="input-field project-create-form-title">
                <label htmlFor="title">Title</label>
                <input
                  className="form-control"
                  placeholder="New project title..."
                  type="title"
                  id="title"
                  name="title"
                  value={this.state.title}
                  onChange={this.handleChange}
                />
              </div>

              
              <div className="input-field project-create-form-title">
                <label htmlFor="subtitle">Subtitle</label>
                <input
                  className="form-control"
                  placeholder="New project subtitle..."
                  type="subtitle"
                  id="subtitle"
                  name="subtitle"
                  value={this.state.subtitle}
                  onChange={this.handleChange}
                />
              </div>

              
              <div className="input-field project-create-form-title">
                <label htmlFor="technology">Technology</label>
                <input
                  className="form-control"
                  placeholder="Technology (e.g. Python, R, JavaScript)..."
                  type="technology"
                  id="technology"
                  name="technology"
                  value={this.state.technology}
                  onChange={this.handleChange}
                />
              </div>
              
              
              <div className="input-field project-create-form-title">
                <label htmlFor="skill">Skill</label>
                <input
                  className="form-control"
                  placeholder="Skill (e.g. Programming, Data modeling)..."
                  type="skill"
                  id="skill"
                  name="skill"
                  value={this.state.skill}
                  onChange={this.handleChange}
                />
              </div>
              
            
              <div className="textarea-field custom-hacky-form project-create-form-title">            
                <label htmlFor="body">Body</label>
                <textarea
                  className="form-control"
                  placeholder="Enter project body as markdown code. If you need to learn markdown code, learn markdown code. Then, do not forget to save me!"
                  type="textarea"
                  id="body"
                  name="body"
                  value={this.state.body}
                  onChange={this.handleChange}
                />
              </div>
              
              <hr />

            

             

              <div className="input-field">
                <label>
                  <span
                    style={{
                      fontSize: '35px',
                      marginRight: '10px',
                    }}
                  >
                    Publish
                  </span>
                  <Toggle
                    checked={this.state.publish} 
                    onChange={this.toggleCheckBox}
                  />
                </label>
              </div>

              <div>
                <button 
                  className="btn btn-dark btn-lg custom-btn-save-cancel"
                  type="submit"
                >
                  Save
                </button>
                <button
                  className="btn btn-outline-secondary btn-lg custom-btn-save-cancel"
                  type="reset"
                  value="Reset"
                  onClick={this.cancelChanges}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-11 create-edit-project-preview-area">
            <div className="">
              <h5 className="createEdit-project-preview-render">Preview</h5>
            </div>
            <br />
            <div>
              <h1 className="createEdit-project-title-render">
                {this.state.title}
              </h1>
              <h3>
                {this.state.subtitle}
              </h3>
              <hr/>
              <p>
                {this.state.technology}
              </p>
              <p>
                {this.state.skill}
              </p>
            </div>
            <hr />
            <div className="createEdit-project-body-render">
              <ReactMarkdown
                source={this.state.body}
                escapeHtml={false}
                skipHtml={false}
              />

            </div>

            <br />

            <div className='sweet-loading'>
                <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={150}
                    color={'#123abc'}
                    loading={this.state.loading}
                />
            </div> 
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectCreate;