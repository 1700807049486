import React from "react";
import { SIGN_IN } from "../auth/AuthActions";
import { grayscale } from "react-syntax-highlighter/dist/esm/styles/hljs";

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: ""
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const creds = this.state;
    SIGN_IN(creds);
  };

  render() {
    return (
      <div className="container signup-custom">
        <div className="row">
          <div className="col-sm-8 col-xs-12 col-sm-offset-1">
            <form onSubmit={this.handleSubmit}>
              <h1 className="">Sign In</h1>

              <br />

              <div className="input-field">
                <label htmlFor="email">Email address</label>
                <input
                  className="form-control auth-form"
                  type="text"
                  id="email"
                  onChange={this.handleChange}
                />
              </div>

              <br />

              <div className="input-field">
                <label htmlFor="password">Password</label>
                <input
                  className="form-control auth-form"
                  type="password"
                  id="password"
                  onChange={this.handleChange}
                />
              </div>

              <br />

              <a 
                href="/accounts/password-reset" 
                style={{
                  display: 'flex',
                  color: 'rgba(0,0,0,.3)',
                }}
              >
                Forgot password?
              </a>

              <br />

              <div className="input-field">
                <button className="">Sign In</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default SignIn;
