import React from 'react';

import { SIGN_UP, SIGN_IN } from '../auth/AuthActions';

class SignUp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            // firstName: '',
            // lastName: '',
            email: '',
            password1: '',
            password2: ''
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.state);
        const creds = this.state;
        SIGN_UP(creds);
    }

    render() {
        return (
            <div className="container signup-custom">
              <div className="row">
                <div className="col-sm-8 col-xs-12 col-sm-offset-1">
                  <form onSubmit={this.handleSubmit}>
                    <h1 className="">Sign Up</h1>
      
                    <br/>
      
                    <div className="input-field">
                      <label htmlFor="username">Username</label>
                      <input className="form-control auth-form" type="text" id="username" onChange={this.handleChange} />
                    </div>
      
                    <br/>
      
                    {/* <div className="input-field">
                      <label htmlFor="firstName">First Name</label>
                      <input className="form-control auth-form" type="text" id="firstName" onChange={this.handleChange} />
                    </div>
      
                    <br/>
      
                    <div className="input-field">
                      <label htmlFor="lastName">Last Name</label>
                      <input className="form-control auth-form" type="text" id="lastName" onChange={this.handleChange} />
                    </div>
      
                    <br/> */}
      
                    <div className="input-field">
                      <label htmlFor="email">Email</label>
                      <input className="form-control auth-form" type="email" id="email" onChange={this.handleChange} />
                    </div>
      
                    <br/>
      
                    <div className="input-field">
                      <label htmlFor="password">Password</label>
                      <input className="form-control auth-form" type="password" id="password1" onChange={this.handleChange} />
                    </div>

                    <br/>

                    <div className="input-field">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <input className="form-control auth-form" type="password" id="password2" onChange={this.handleChange} />
                    </div>
      
                    <br/>
      
                    <div className="input-field">
                      <button className="">Create account</button>
                    </div>
                </form>
                </div>
              </div>
            </div>
        )
    }
}

export default SignUp;