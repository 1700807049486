// // UTILIZATION
// import Config from "../../config/Config.js";
// Config().baseUrl

const Config = () => {
    let url;
    if (process.env.NODE_ENV === 'production') {
        url = "https://api.inertia7.com";
    } else if (process.env.NODE_ENV === 'development') {
        url = "https://api.inertia7.com";
        // url = "http://127.0.0.1:8000";
    }

    return (
        {
            // base projects endpoints
            url: `${url}/`,
            baseUrl: `${url}/api/v1/projects/`,
            

            // query
            projectByTechnologyUrl: `${url}/api/v1/projects/?technology=`,

            // user endpoints
            userUrl: `${url}/api/v1/users/`,
            extendedUserUrl: `${url}/api/v1/users/extended/`, // needs user_id appended at end

            

            // auth endpoints
            userAuthUrl: `${url}/api/v1/auth/rest-auth/user/`,
            signupUrl: `${url}/api/v1/auth/rest-auth/registration/`,
            signinUrl: `${url}/api/v1/auth/rest-auth/login/`,
            signoutUrl: `${url}/api/v1/auth/rest-auth/logout/`,
            pwdResetUrl: `${url}/api/v1/auth/rest-auth/password/reset/`,
            pwdResetConfirmUrl: `${url}/api/v1/auth/rest-auth/password/reset/confirm/`,




            // settings endpoints
            pwdChangeUrl: `${url}/api/v1/auth/rest-auth/password/change/`
        }
    )
};

export default Config;

