import axios from "axios";
import Config from "../../config/Config.js";


// --------------------------------------------
// core authentication functionality 
// --------------------------------------------


// hits API to authenticate user
const SIGN_IN = (creds) => {
  const email = creds.email;
  const password = creds.password;
  axios
  .post(
    `${Config().signinUrl}`,     
    {
      email: email,
      password: password
    }    
  )
  .then(res => {
    const token = res.data.key;
    console.log(res);
    GET_USER_DATA_AFTER_API_AUTH(creds, token, "/home");
  })
  .catch(err => {
    try {
      const errMsgs = err.response.data;
      const keys = Object.keys(errMsgs);
      if (keys.length < 2) {
        for (const key of keys) {
          alert(errMsgs[key])
        }
      } else if (keys.length == 1) {
        alert(errMsgs[keys])
      }
    } catch (error) {
      console.log(error);
    }
  });
}


// Gets user data upon authentication
const GET_USER_DATA_AFTER_API_AUTH = (creds, token, redirect_to) => {
  axios
    .get(
      `${Config().url}api/v1/users/?email=${creds.email}`,
    )
    .then(res => {
      console.log(res);
      const user_data = res.data[0];
      localStorage.setItem("TOKEN", token);
      localStorage.setItem("USER_PK", user_data.pk);
      localStorage.setItem("USERNAME", user_data.username);
      localStorage.setItem("USER_EMAIL", user_data.email);
      localStorage.setItem("USER_FIRST_NAME", user_data.first_name);
      localStorage.setItem("USER_LAST_NAME", user_data.last_name);
      localStorage.setItem("IS_AUTHENTICATED", true);
      console.log(localStorage);
      window.location = redirect_to;
    })
}


// creates new user, prompts user to confirm email
const SIGN_UP = (creds) => {
  axios
    .post(`${Config().signupUrl}`, {
      username: creds.username,
      email: creds.email,
      password1: creds.password1,
      password2: creds.password2
    })
    .then(res => {
      const token = res.data.key;
      if(token !== null){
        localStorage.setItem("USER_EMAIL", creds.email);
        window.location = "/accounts/confirm";
      }
    })
    .catch(err => {
      console.log(err);
      try {
        const errMsgs = err.response.data;
        const keys = Object.keys(errMsgs);
    
        if (keys.length < 2) {
          for (const key of keys) {
            alert(errMsgs[key])
          }
        } else if (keys.length == 1) {
          alert(errMsgs[keys])
        }
      } catch (error) {
        console.log(error);
      }
    });
};


// clears localStorage, signing out user
const SIGN_OUT = (token) => {
  axios
    .post(
      `${Config().signoutUrl}`,
      {
        'headers': {
          "Authorization": `Token ${token}`
        }
      }
    )
  localStorage.clear();
  localStorage.setItem("IS_AUTHENTICATED", false);
  window.location.href = "/";
};


// resets password, step 1/2
const PASSWORD_RESET_1 = (email) => {
  axios
    .post(
      `${Config().pwdResetUrl}`,
      {'email': email}
    )
    .then(res => {
      console.log(res);
      alert(res.data.detail);
    })
};

// resets password, step 2/2
const PASSWORD_RESET_2 = (new_password1, new_password2, uid, token) => {
  axios
    .post(
      `${Config().pwdResetConfirmUrl}`,
      {
        'new_password1' : new_password1,
        'new_password2' : new_password2,
        'uid' : uid,
        'token' : token
      }
    )
    .then(res => {
      console.log(res);
      alert(res.data.detail);
    })
};


// determines authentication data from localStorage
const RESOLVE_LOCAL_AUTH_STATE = () => {
  return {
    is_authenticated: localStorage.getItem("IS_AUTHENTICATED"),
    token: localStorage.getItem("TOKEN"),
    username: localStorage.getItem("USERNAME")
  };
};


// determines user data from localStorage
const RESOLVE_LOCAL_USER_STATE = () => {
  return {
    username: localStorage.getItem("USERNAME"),
    pk: localStorage.getItem("USER_PK"),
    email: localStorage.getItem("USER_EMAIL"),
    first_name: localStorage.getItem("USER_FIRST_NAME"),
    last_name: localStorage.getItem("USER_LAST_NAME")
  };
};

export {
  SIGN_UP,
  SIGN_IN,
  SIGN_OUT,
  PASSWORD_RESET_1,
  PASSWORD_RESET_2,
  RESOLVE_LOCAL_AUTH_STATE,
  RESOLVE_LOCAL_USER_STATE,
};