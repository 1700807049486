import React from "react";

import {
  RESOLVE_LOCAL_AUTH_STATE,
  RESOLVE_LOCAL_USER_STATE
} from "../auth/AuthActions";

import axios from "axios";

import Config from "../../config/Config.js";

class PwdChange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: RESOLVE_LOCAL_AUTH_STATE(),
      user: RESOLVE_LOCAL_USER_STATE(),
      current_password: "",
      new_password1: "",
      new_password2: ""
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    axios
    .post(
      `${Config().pwdChangeUrl}`,     
      {
        new_password1: this.state.new_password1,
        new_password2: this.state.new_password2
      },
      {
        username: this.state.auth.username,
        auth: {
          username: this.state.auth.username,
          password: this.state.current_password
        }
      }
    )
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    });
  };

  render() {
    return (
            <form onSubmit={this.handleSubmit}>
              <h1 className="">Password</h1>

              <hr />

              <div className="input-field">
                <label htmlFor="current_password">Current password</label>
                <input
                  className="form-control auth-form"
                  type="password"
                  id="current_password"
                  onChange={this.handleChange}
                />
              </div>

              <br />

              <div className="input-field">
                <label htmlFor="new_password1">New password</label>
                <input
                  className="form-control auth-form"
                  type="password"
                  id="new_password1"
                  onChange={this.handleChange}
                />
              </div>

              <br />

              <div className="input-field">
                <label htmlFor="new_password2">Confirm new password</label>
                <input
                  className="form-control auth-form"
                  type="password"
                  id="new_password2"
                  onChange={this.handleChange}
                />
              </div>

              <br />

              <div className="input-field">
                <button className="">Change password</button>
              </div>
            </form>
    );
  }
}

export default PwdChange;