import React from "react";
import { PASSWORD_RESET_1 } from "./AuthActions";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ""
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const email = this.state.email;
    PASSWORD_RESET_1(email);
  };

  render() {
    return (
      <div className="container signup-custom">
        <div className="row">
          <div className="col-sm-8 col-xs-12 col-sm-offset-1">
            <form onSubmit={this.handleSubmit}>
              <h1 className="">Reset your password...</h1>
              <br />
              <div className="input-field">
                <label htmlFor="email">Email address</label>
                <input
                  className="form-control auth-form"
                  type="text"
                  id="email"
                  onChange={this.handleChange}
                />
              </div>
              <br />
              <div className="input-field">
                <button className="">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;