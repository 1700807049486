import React from "react";
import axios from "axios";
import { jsx, css } from '@emotion/core'

import Config from "../../config/Config.js";
import ProjectCard from "./ProjectCard";
import SweetLoadingSpinner from '../eyeCandy/SweetLoadingSpinner';


class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project_list: [],
      loading: true
    };
  }

  componentDidMount() {

    // query for published projects
    axios.get(`${Config().baseUrl}?publish=${this.props.publish}&limit=6`).then(res => {
      
      // reset state
      this.setState({
        project_list: res.data.results,
        loading: false
      });
    });
  }

  render() {
    return (
      <div>
        <SweetLoadingSpinner loadingState={this.state.loading} />
        <div className="row">
          {this.state.project_list.map(project => {
            return <ProjectCard project={project} key={project.id} />;
          })}
        </div>
      </div>
    );
  }
}

export default ProjectList;
