/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faFire } from '@fortawesome/free-solid-svg-icons';

class ProjectCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            project: props.project,
            loading: true
        }
    }

    componentDidMount() {
        this.setState({
        loading: false
        });
        
    }

    render() {
        return (
            // <div className="container col-xl-12 col-lg-12 col-md-12 col-sm-6 col-xs-12" key={this.state.project.id}>
            <div className="container col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12" key={this.state.project.id}>
                <div className="project-card-custom container zoom">
                    <Link to={`/projects/${this.state.project.id}`}>

                        <h3
                            
                            style={{
                                // color: '#59c6c1',
                                color: 'black',
                                fontWeight: '800',
                                fontSize: '1em',
                                // color: 'rgb(36, 81, 93)',
                                // fontFamily: 'Montserrat',
                            }}
                        >
                            {this.state.project.title}
                        </h3>
                        <h5>{this.state.project.subtitle}</h5>
                        <hr />
                        <h5
                            style={{
                                // color: 'rgb(36, 81, 93)',
                                color: '#59c6c1',
                                fontFamily: 'Courier',
                                fontWeight: '700',
                                // textAlign: 'center',
                            }}
                        >
                            {this.state.project.technology} <span style={{color:'lightgray'}}>|</span> {this.state.project.skill}
                        </h5>

                        {/* <h5><FontAwesomeIcon icon={faCode} color="black" /> {this.state.project.technology}</h5>
                        <h5><FontAwesomeIcon icon={faFire} color="black" /> {this.state.project.skill}</h5> */}
                        {/* <h6>#{this.state.project.id}</h6> */}
                    </Link>
                </div>
            </div>
        )
    }
}

export default ProjectCard;