import React from "react";

import {
  RESOLVE_LOCAL_AUTH_STATE,
  RESOLVE_LOCAL_USER_STATE
} from "../auth/AuthActions";

import axios from "axios";

import Config from "../../config/Config.js";

import ReactMarkdown from 'react-markdown';

class ProfileChange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: RESOLVE_LOCAL_AUTH_STATE(),
      user: RESOLVE_LOCAL_USER_STATE(),
      bio: '',
      cv: '',
      website: '',
      picture_link: ''
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    axios
    .put(
      `${Config().extendedUserUrl}${this.state.user.pk}/`,     
      {
        user: this.state.user.pk,
        bio: this.state.bio,
        cv: this.state.cv,
        website: this.state.website,
        picture_link: this.state.picture_link
      },
      {
        'headers': {
          "Authorization": `Token ${this.state.auth.token}`
        }
      }
    )
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    });
  };

  componentDidMount() {
    
    axios.get(
        `${Config().extendedUserUrl}${this.state.user.pk}`  
    ).then(res => {
      this.setState({
        bio: res.data.bio,
        cv: res.data.cv,
        website: res.data.website,
        picture_link: res.data.picture_link
      });
      console.log(res);
    });
  }

  render() {
    return (          
            <form onSubmit={this.handleSubmit}>
              <h1 className="">Profile</h1>

              <hr />
              
              <div className="input-field">
                <label htmlFor="bio">Short bio</label>
                <input
                  className="form-control auth-form"
                  type="text"
                  id="bio"
                  value={this.state.bio}
                  onChange={this.handleChange}
                />
              </div>

              <br />

              <div className="input-field">
                <label htmlFor="website">Website</label>
                <input
                  className="form-control auth-form"
                  type="text"
                  id="website"
                  value={this.state.website}
                  onChange={this.handleChange}
                />
              </div>

              <br />

              <div className="row">
                <div className="col-sm-6 col-xs-6">

              <div className="input-field">
                <label htmlFor="picture_link">Picture (URL)</label>
                <input
                  className="form-control auth-form"
                  type="text"
                  id="picture_link"
                  value={this.state.picture_link}
                  onChange={this.handleChange}
                />
              </div>

              </div>

              <div className="col-sm-6 col-xs-6">

              <img src={this.state.picture_link} height="200px" style={{borderRadius: 400/ 2, alignItems: 'center',}} />

              </div>
              </div>

              <br />
              <br />
            
            <div className="row">
                <div className="col-sm-6 col-xs-6">

            
                <div className="textarea-field custom-hacky-form">            
                    <label htmlFor="body">Curriculum vitae (Markdown)</label>
                    <textarea
                    className="form-control"
                    type="textarea"
                    id="cv"
                    name="cv"
                    value={this.state.cv}
                    onChange={this.handleChange}
                    />
                </div>
                </div>
                <div className="col-sm-6 col-xs-6">

                <div className="createEdit-project-body-render">
                <ReactMarkdown
                    source={this.state.cv}
                />
                </div>
            </div>
            </div>

              <br />

              <div className="input-field">
                <button className="">Save changes</button>
              </div>
            </form>
    );
  }
}

export default ProfileChange;